/* .HeaderWrapper{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  min-block-size: 50px;
} */
.dx-datagrid {  
  background-color: #349de3;  
}  
.dx-datagrid-headers .dx-header-row {  
  color: #000;  
}  
.dx-pager .dx-page-sizes .dx-selection, .dx-pager .dx-pages .dx-selection {
  background-color: white;
  color: #349de3;
}
.injectedHTML {
  background: white;
  padding: 20px;
  border-radius: 10px;
}

.textoMenu {
  height: 20px;
}

.chatbot {
  opacity: 0;
  position: fixed;
  bottom: 0;
}

.errorHeader {
  color: #2d68c4 !important;
  margin: auto;
  margin-top: 50px;
  text-align: center;
}

.icono_soporte {
  background-image: url("../../assets/images/soporte.png");
  width: 56px;
  height: 56px;
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: space-between;
  margin: auto;
}

#root {
  min-height: 100%;
  background: #ffffff;
}

.DataShow {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;

}

.DataContainer {
  width: 100%;
  height: 100%;
  margin: auto;
  padding-left: 325px;

}

.BodyBackground {
  /* background: linear-gradient(320.54deg, #00069F 0%, #120010 72.37%), linear-gradient(58.72deg, #69D200 0%, #970091 100%), linear-gradient(121.28deg, #8CFF18 0%, #6C0075 100%), linear-gradient(121.28deg, #8000FF 0%, #000000 100%), linear-gradient(180deg, #00FF19 0%, #24FF00 0.01%, #2400FF 100%), linear-gradient(52.23deg, #0500FF 0%, #FF0000 100%), linear-gradient(121.28deg, #32003A 0%, #FF4040 100%), radial-gradient(50% 72.12% at 50% 50%, #EB00FF 0%, #110055 100%);
background-blend-mode: screen, color-dodge, color-burn, screen, overlay, difference, color-dodge, normal;
background: url('https://lh3.googleusercontent.com/proxy/KXFZgkvch04al9R5I58oGvgzq6HceIdvTLt2NIA7-yvUtRvT9Pgf69ZxkUm0BtAUry9wqyTLf5YS_nLhRwlu3xB8gYiblFWc8-oyV-az25SJU13Ma9Dee-6TPpEDoSTJipO7AM4igpTIsB5N5OE'); */
  /* background: url('https://hdwallsource.com/img/2014/6/blue-and-white-wallpaper-8896-9236-hd-wallpapers.jpg'); */

  /* background: url('http://contratodeobras.com/images/fondo-azul-jpg.jpg'); */
  /* background: url('../images/fondo_azul.jpeg'); */
  background: #ffffff;

  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
}

.BigContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;

  width: 100%;
  height: 100%;
  background-color: #ffffff;
  overflow-y: auto;

}

.BigContainererror {
  display: grid;
  grid-template-columns: 400px minmax(0, 1fr);
  width: 100%;
  height: 100%;
  -webkit-animation: 15s colorgroup infinite;
  /* background: rgba( 186, 171, 171, 0.40 ); */
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2.0px);
  -webkit-backdrop-filter: blur(2.0px);
  border-radius: 10px;
  /* border: 1px solid rgba( 255, 255, 255, 0.18 ); */
  overflow-y: auto;

}

@media screen and (max-width: 1024px) {
  .BigContainer {
    grid-template-columns: 1fr;
  }
}

.MenuCircular {
  display: block;
  position: static;
  width: 100px;
  height: 100px;
}

.icon {
  position: relative;
  cursor: pointer;
  margin: auto;
  /* color: #2d68c4; */
  color: #4fb5de;
  border-radius: 50%;
  height: 140px;
  width: 140px;
  line-height: 140px;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  transition: 0.24s 0.2s;
}

.icons {
  position: absolute;
  /* background: white; */
  cursor: pointer;
  margin: auto;
  color: #1d58b4;
  border-radius: 50%;
  height: 140px;
  width: 140px;
  line-height: 140px;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  transition: 0.24s 0.2s;
}

/* 360 principal */
.icon:hover {
  background: rgba(255, 255, 255, 0.35);
}

.icons:hover {
  background: rgba(255, 255, 255, 0.35);
}

.menu {
  position: absolute;
  top: -95px;
  left: -95px;
  border: 145px solid transparent;
  cursor: default;
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  transition: transform 1.4s 0.07s;
  -webkit-animation: 1.5s rotateelement 1;

  /* z-index: -5; */
}

.menu:hover {

  /* z-index: -5; */
}


.menus {
  position: absolute;
  top: -45px;
  left: -45px;
  border: 50px solid transparent;
  cursor: default;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 1.4s 0.07s;

  /* z-index: -5; */
}

.spread {
  position: absolute;
  height: 71px;
  width: 71px;
  top: -30px;
  left: 0;
  transform-origin: 0;
  transition: all 0.5s 0.1s;
  z-index: 40;
}

.spreads {
  position: absolute;
  top: -40px;
  left: -80px;
  transform-origin: 150px 110px;
  transition: all 0.5s 0.1s;
}

.menus {
  transition: transform 0.4s 0.08s, z-index 0s 0.5s;
  transform: scale(1);
  z-index: 5;
}

.spreads,
.icon:hover .units {
  transition: all 0.6s;
}

.spreads:nth-child(1) {
  transition-delay: 0.02s;
  transform: rotate(45deg);
}

.spreads:nth-child(1) .units {
  transition-delay: 0.08s;
  transform: rotate(-45deg);
}

.spreads:nth-child(2) {
  transition-delay: 0.04s;
  transform: rotate(90deg);
}

.spreads:nth-child(2) .units {
  transition-delay: 0.08s;
  transform: rotate(-90deg);
}

.spreads:nth-child(3) {
  transition-delay: 0.06s;
  transform: rotate(135deg);
}

.spreads:nth-child(3) .units {
  transition-delay: 0.12s;
  transform: rotate(-135deg);
}

.spreads:nth-child(4) {
  transition-delay: 0.08s;
  transform: rotate(180deg);
}

.spreads:nth-child(4) .units {
  transition-delay: 0.16s;
  transform: rotate(-180deg);
}

.spreads:nth-child(5) {
  transition-delay: 0.1s;
  transform: rotate(225deg);
}

.spreads:nth-child(5) .units {
  transition-delay: 0.2s;
  transform: rotate(-225deg);
}

.spreads:nth-child(6) {
  transition-delay: 0.12s;
  transform: rotate(270deg);
}

.spreads:nth-child(6) .units {
  transition-delay: 0.24s;
  transform: rotate(-270deg);
}

.spreads:nth-child(7) {
  transition-delay: 0.14s;
  transform: rotate(315deg);
}

.spreads:nth-child(7) .units {
  transition-delay: 0.28s;
  transform: rotate(-315deg);
}

.spreads:nth-child(8) {
  transition-delay: 0.16s;
  transform: rotate(360deg);
}

.spreads:nth-child(8) .units {
  transition-delay: 0.32s;
  transform: rotate(-360deg);
}

/* .spreads:nth-child(9) {
  transition-delay: 0.18s;
  transform: rotate(405deg);
}
.spreads:nth-child(9) .units {
  transition-delay: 0.36s;
  transform: rotate(315deg);
} */

.icons:hover .menu {
  transition: transform 0.4s 0.08s, z-index 0s 0.5s;
  transform: scale(1);
  z-index: 5;
}

.icons:hover .spread,
.icon:hover .unit {
  transition: all 0.6s;

}

.icons:hover .spread:nth-child(1) {
  transition-delay: 0.02s;
  /* transform: rotate(70deg); */
  transform: rotate(90deg);
  transform: translate(0px, 220px);
}

.icons:hover .spread:nth-child(1) .unit {
  transition-delay: 0.04s;
  /* transform: rotate(650deg); */
  transform: rotate(720deg);
}

.icons:hover .spread:nth-child(2) {
  transition-delay: 0.04s;
  transform: rotate(110deg);
  transform: translate(0px, 292px);
}

.icons:hover .spread:nth-child(2) .unit {
  transition-delay: 0.08s;
  /* transform: rotate(610deg); */
  transform: rotate(720deg);
}

.icons:hover .spread:nth-child(3) {
  transition-delay: 0.06s;
  transform: rotate(150deg);
  transform: translate(0px, 364px);
}

.icons:hover .spread:nth-child(3) .unit {
  transition-delay: 0.12s;
  /* transform: rotate(570deg); */
  transform: rotate(720deg);
}

.icons:hover .spread:nth-child(4) {
  transition-delay: 0.08s;
  transform: rotate(190deg);
  transform: translate(0px, 436px);
}

.icons:hover .spread:nth-child(4) .unit {
  transition-delay: 0.16s;
  transform: rotate(720deg);
  /* transform: rotate(530deg); */
}

.icons:hover .spread:nth-child(5) {
  transition-delay: 0.1s;
  transform: rotate(245deg);
  transform: translate(0px, 508px);
}

.icons:hover .spread:nth-child(5) .unit {
  transition-delay: 0.2s;
  transform: rotate(720deg);
  /* transform: rotate(475deg); */
}

.icons:hover .spread:nth-child(6) {
  transition-delay: 0.12s;
  transform: rotate(285deg);
  transform: translate(0px, 580px);
}

.icons:hover .spread:nth-child(6) .unit {
  transition-delay: 0.24s;
  transform: rotate(720deg);
  /* transform: rotate(435deg); */
}

.icons:hover .spread:nth-child(7) {
  transition-delay: 0.14s;
  transform: rotate(325deg);
  transform: translate(0px, 652px);
}

.icons:hover .spread:nth-child(7) .unit {
  transition-delay: 0.28s;
  transform: rotate(720deg);
  /* transform: rotate(395deg); */
}

.icons:hover .spread:nth-child(8) {
  transition-delay: 0.16s;
  transform: rotate(365deg);
  transform: translate(0px, 724px);
}

.icons:hover .spread:nth-child(8) .unit {
  transition-delay: 0.32s;
  transform: rotate(720deg);
  /* transform: rotate(355deg); */
}

.icons:hover .spread:nth-child(9) {
  transition-delay: 0.18s;
  transform: rotate(405deg);
  transform: translate(0px, 796px);
}

.icons:hover .spread:nth-child(9) .unit {
  transition-delay: 0.36s;
  transform: rotate(720deg);
  /* transform: rotate(315deg); */
}

.unit:checked {
  height: 400px;
}

/* END .icon:hover */
.unit {
  position: absolute;
  left: 80px;
  bottom: 0;
  width: 0;
  height: 0;
  line-height: 1px;
  margin-left: 0;
  /* background:darkblue; */
  background: rgb(2, 181, 248);
  background: radial-gradient(circle, rgba(2, 181, 248, 1) 10%, rgba(1, 18, 111, 1) 83%);
  /* opacity: 0.5; */
  opacity: 0.9;

  border-radius: 50%;
  text-align: center;
  font-size: 1px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.8s ease-in-out, color 0.1s, background 0.1s;
  position: absolute;
  font-size: 35%;
  text-decoration: none;
  width: 70px;
  height: 70px;
  line-height: 80px;
  color: #ffffff;
  border-radius: 50%;
  transition: 0.6s;
  z-index: 1000;
}

.unit:hover {
  position: absolute;
  left: 80px;
  bottom: 0;
  width: 0;
  height: 0;
  line-height: 1px;
  margin-left: 0;
  /* background:darkblue; */
  background: #01126f;
  /* opacity: 0.5; */
  opacity: 0.9;

  border-radius: 50%;
  text-align: center;
  font-size: 1px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.8s ease-in-out, color 0.1s, background 0.1s;
  position: absolute;
  font-size: 35%;
  text-decoration: none;
  width: 70px;
  height: 70px;
  line-height: 80px;
  color: #ffffff;
  border-radius: 50%;
  transition: 0.6s;
  z-index: 1000;
}

.units {
  position: absolute;
  left: 50%;
  bottom: 60%;
  width: 0;
  height: 0;
  line-height: 1px;
  margin-left: 0;
  background: #019df4;
  opacity: 0.9;
  box-shadow: inset 0 0 10vmin 0 #3e7ca1;
  border-radius: 50%;
  text-align: center;
  font-size: 1px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.8s ease-in-out, color 0.1s, background 0.1s;
  position: absolute;
  font-size: 27%;
  text-decoration: none;
  line-height: 80px;
  font-size: 50%;
  text-decoration: none;
  width: 130px;
  height: 130px;
  line-height: 130px;
  color: white;
  border-radius: 50%;
  transition: 0.6s;
}

.units:hover {
  background-color: rgb(240, 248, 255);
  color: rgb(0, 0, 0);
}

/* Just CodePen layout stuff: */
body,
a,
ul,
li {
  margin: 0;
  outline: 0;
  padding: 0;
  list-style: none;
}

html,
body {
  height: 100%;
  min-height: 300px;
}

body {
  font-family: sans-serif;
  /* background: radial-gradient(#00bfff, #1e90ff); */
  background-image: url("/src/assets/images/imagen2.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background: #ccc;
  width: 100%;
  transform: translate3d(0, 0, 0);
}

.icon {
  top: 100px;
  margin-top: 150px;
}

.icons {
  left: 0;
  margin-top: 0px;
  z-index: 50;
}

.contenedorr {
  height: 40% !important;
}

.contenedor {
  height: 60% !important;
  /* background-color: #6A737C; */
  /* margin: 1% 1%; */
}

.div1 {
  border: solid #cccccc 0.5px;
  color: #2d68c4;
  height: 700px;
  border-radius: 40px;
  /* margin-top: -70px; */
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
}

.App {
  text-align: center;
  width: 50%;
  overflow: hidden;
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
}

.Inicio {
  text-align: center;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* width: 100%; */
}

.Loader {
  color: darkblue;
  width: 300px;
}

.asd {
  display: flex;
  background-color: red;
  /* z-index: -1; */
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: rgb(73, 79, 82);
  color: white;
}

.nav-links {
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-main {
  background-color: #61dafb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Contract-box {
  display: flex;
  justify-content: center;
}

.Left-box {
  /* padding: 20px; */
  width: 500px;
  height: 140px;
  color: white;
}

.Left-box-Appear {
  padding: 20px;
  width: 500px;
  height: 140px;
  color: white;
}

.Left-box h4 {
  color: #019df4;
}

.Left-box-Appear h4 {
  color: #019df4;
}

.Rigth-box {
  float: right;
  justify-content: center;
  width: 500px;
  height: 140px;
}

@media all and (max-width: 425px) {
  .Left-box-Appear {
    padding: 20px 10px 0px 0px;
  }

  .Rigth-box {
    display: none;
  }

  #iconoup {
    display: none;
  }
}

@media all and (min-width: 426px) and (max-width: 768px) {
  .Rigth-box {
    display: none;
  }

  .Left-box-Appear {
    padding: 20px 90px 0px 0px;
  }

  #iconoup {
    display: none;
  }
}

section div.Status-box {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.Block-temperatura {
  display: flex;
  justify-content: center;
}

.Temp-local {
  width: 125px;
  height: 60px;
  float: left;
  border-bottom: 1px solid #cccccc;
  border-right: 1px solid #ccc;
}

.Temp-local h6 {
  margin-top: 10px;
  color: #00ced1;
}

.Temp-hub {
  width: 125px;
  height: 60px;
  float: right;
  border-bottom: 1px solid #ccc;
}

.Temp-hub h6 {
  margin-top: 10px;
  color: #00ced1;
}

#temp {
  color: lawngreen;
}

#temp1 {
  color: lawngreen;
}

.label {
  margin-top: -13px;
}

.Block-estado {
  display: flex;
  justify-content: center;
}

.Estado-tecnico {
  width: 125px;
  height: 60px;
  float: left;
  border-right: 1px solid #ccc;
}

.Estado-tecnico h6 {
  margin-top: 10px;
  color: #00ced1;
}

.Estado-comercial {
  width: 125px;
  height: 60px;
  float: right;
}

.Estado-comercial h6 {
  margin-top: 10px;
  color: #00ced1;
}

#temp2 {
  color: yellow;
}

#temp3 {
  color: lawngreen;
}

#temp4 {
  color: red;
}

.Expand-graphic {
  background-color: white;
  width: 1400px;
  height: 500px;
  border-radius: 15px;
}

#bot {
  position: absolute;
  border-radius: 40px;
  height: 60px;
  width: 60px;
  margin-top: -70px;
  right: 1px;
}

#iconoup {
  color: white;
  width: 28px;
  height: 28px;
}

#iconodown {
  color: white;
  width: 28px;
  height: 28px;
}

.iconsdown {
  display: none;
}

.iconsup {
  display: none;
}

.iconsdowns {
  color: darkblue;
  height: 13px;
}

.iconsups {
  color: darkblue;
  height: 13px;
}

/* display: inline-block; */
#table {
  width: 1050px;
  margin-top: 50px;
  display: inline-block;
  /* background: darkmagenta; */
}

.agrandar {
  height: 500px;
  color: #2d68c4;
  border-radius: 40px;
  margin: 30px;
  margin-top: -55px;
  text-align: center;
}

.agrandarsegundo {
  color: #2d68c4;
  border-radius: 40px;
  height: 200px;
  margin: 30px;
  margin-top: -2px;
  height: 447px;
  text-align: center;
}

.superiorizq {
  display: none;
}

.superiorder {
  display: none;
}

.superiordersegundo {
  width: 125px;
  height: 60px;
  float: left;
  border-bottom: 1px solid #cccccc;
  border-right: 1px solid #ccc;
}

.superiorizqsegundo {
  width: 125px;
  height: 60px;
  float: right;
  border-bottom: 1px solid #ccc;
}

.cuadroh6izquierdo {
  margin-top: 10px;
  color: #00ced1;
}

.cuadroh6derecho {
  margin-top: 10px;
  color: #00ced1;
}

.desaparecer {
  display: none;
}

h1 {
  text-align: center;
  padding-top: 10px;
  color: #2d68c4 !important;
}

/* #bote:focus .div1{
  background: blue;
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/***CHAT***/
#div-chat {
  position: relative;
  width: 250px;
  height: 250px;
}

#textarea-chat {
  position: relative;
  width: 235px;
  height: 220px;
  border: 20px;
}

#button-chat {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

/*********FORM DATA GENERAL***********/

.form-data {
  box-sizing: border-box;
  margin: auto;
  width: 100%;
  max-width: 900px;
  border-radius: 7px;
}

#outlined-basic {
  margin-bottom: 15px;
  padding: 15px;
  font-size: 16px;
  border-radius: 3px;
  border: solid #a9a9a9 1px;
  color: white;
  background-color: none;
}

.contenedor-inputs {
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input-70 {
  width: 70%;
  text-justify: auto;
}

.input-80 {
  width: 80%;
  text-justify: auto;
}

.input-90 {
  width: 90%;
  text-justify: auto;
}

.input-100 {
  width: 100%;
}

.input-130 {
  width: 130%;
}

#titulo-data {
  color: #74a5f3;
  font-weight: bold;
}

/*****TABLA******/
.form-incid {
  box-sizing: border-box;
  /* margin: 20px; */
  width: 100%;
  /* max-width: 900px; */
  width: 1250px;
  margin-top: 10px;
  display: inline-block;
}

.tbl-n1 {
  height: 570px;
  width: 100%;
}

@keyframes colorgroup {
  0% {
    background: rgba(2, 181, 248, 1);
  }

  50% {
    background: rgba(1, 18, 111, 1);
  }

  100% {
    background: rgba(2, 181, 248, 1);
  }
}

@keyframes rotateelement0 {
  0% {
    transform: rotate(360deg);
    opacity: 1;
  }

  100% {

    transform: rotate(0deg);
    opacity: 0;
  }
}

@keyframes rotateelement {
  0% {
    transform: rotate(0deg);
    opacity: 0;
  }

  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}


.card-info-content {
  margin-top: 0px;
  padding-top: 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  max-height: 120px;
  width: 100%;
  padding-left: 325px;
  -webkit-box-shadow: -9px 9px 7px -9px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: -9px 9px 7px -9px rgba(0, 0, 0, 0.53);
  box-shadow: -9px 9px 7px -9px rgba(0, 0, 0, 0.53);
}

.card-info {
  margin-left: 15px;
  width: 100%;
  max-width: 240px;
  height: 100%;
  max-height: 80px;
  min-height: 80px;
  padding: 1rem;
  border-radius: 9px;
  -webkit-box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.6);
  box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.6);
  background: rgb(1, 18, 111);
  background: linear-gradient(79deg, rgba(1, 18, 111, 1) 1%, rgba(2, 181, 248, 1) 100%);

}

.card-info h3 {
  font-size: 20px;
  margin: 0px;
  padding: 0px;
  color: #ffffff;
}

.card-info span {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  color: #ffffff;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

}

.menu-content {
  padding: 0 5px;
  height: 100vh;
  width: 400px;
  display: flex;
  position: fixed;
  align-items: flex-start;
  justify-content: center;


  backdrop-filter: blur(2.0px);
  -webkit-backdrop-filter: blur(2.0px);
  -webkit-animation: 1.5s fadein 1;

  /* border: 1px solid rgba( 255, 255, 255, 0.18 ); */
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.btn-menu-show {
  position: fixed;
  bottom: 3%;
  left: 2%;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: rgb(2, 181, 248);
  background: radial-gradient(circle, rgba(2, 181, 248, 1) 10%, rgba(1, 18, 111, 1) 83%);
  color: #ffffff;
  font-weight: bold;
}

.btn-menu-show-superior {
  position: fixed;
  top: 3%;
  left: 350px;
  background-color: transparent;
  border: none;
  font-weight: bold;
  z-index: 5;
}

.btn-menu-show-superior svg {}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}