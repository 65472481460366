#table {
    background: none;
    
}
.ae-zone {
    background-color: #2d68c4;

  }



