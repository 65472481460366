.Help-Button {
    width: 70px;
    height: 70px;
    position: absolute;
    bottom: 1em;
    transition: all 100ms ease-out 0s;
    right: 20px;
    left: auto;
    transform: none;
    opacity: 1;
}

.Style-Button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    background: #3F69A2;
    color: rgb(255, 255, 255);
    border-radius: 50%;
    cursor: pointer;
}

.open-button {
    appearance: none;
    cursor: pointer;
    background: #3F69A2;
    border: 0px;
    display: inline-block;
    margin: 0px;
    padding: 0.5em;
    color: white;
}

.chat-popup {
    display: none;
    position: fixed;
    background: white;
    bottom: 0;
    right: 15px;
    border: 3px solid #f1f1f1;
    z-index: 9;
    border-radius: 20px 20px 0px 0px;
  }

  .form-container {
    text-align: center;
    max-width: 300px;
    padding: 10px;
    background-color: white;
    color: #2d68c4;
    border-radius: 20px 20px 0px 0px;
  }

  .contentHead {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 102%;
    margin-left: -4px;
    margin-top: -13px;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 0.4em;
    height: 35px;
    background: #2d68c4;
    border-radius: 20px 20px 0px 0px;
  }

  .headColor {
    width: 100%;
    text-align: center;
    font-size: 0.9em;
  }

  .form-container textarea {
    width: 90%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
    min-height: 200px;
    border-radius: 15px;
  }

  .form-container textarea:focus {
    background-color: #ddd;
    outline: none;
  }

  .form-container .btn {
    color: #BBBB;
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    width: 2%;
    opacity: 0.8;
  }
  
  .form-container .cancel {
    font-size: 16px;
    line-height: 16px;
    float: right;
  }

  .form-container .btn:hover, .open-button:hover {
    opacity: 1;
  }

#iconHeadset{
    width: 38px;
    height: 38px;
    color: white;
}



